.advantages {
    background-color: #08603a;
    color: #fff;
    padding: 140px 0;
    background-size: cover;
    background-image: url(../img/background-advantages1920.jpg);
    @include mediaw(1919px) {
        background-image: url(../img/background-advantages.jpg);
    }
    @include mediaw(576px) {
        padding: 98px 0;
    }
    .section-title span {
        color: #fff;
        &::before, &::after {
            background-color: #fff;
        }
    }
    &__title {
        font-family: $fontUniNeueBold;
        font-size: 60px;
        text-align: center;
        text-transform: uppercase;
        margin-top: 45px;
        @include mediaw(768px) {
            margin-top: 32px;
            font-size: 42px;
        }
    }
    &__description {
        font-family: $fontUniNeueRegular;
        font-size: 30px;
        line-height: 48px;
        text-align: center;
        margin: 30px auto 0;
        max-width: 465px;
        @include mediaw(768px) {
            font-size: 21px;
            line-height: 34px;
            margin: 21px auto 0;
        }
    }
    &__row {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__image {
        position: absolute;
        top: 45px;
        left: 50%;
        transform: translateX(-50%);
        @include mediaw(1199px) {
            display: none;
        }
    }
    &__item {
        width: 50%;
        text-align: right;
        margin-top: 70px;
        @include mediaw(1199px) {
            text-align: center;
        }
        @include mediaw(768px) {
            margin-top: 49px;
            padding: 0 15px;
        }
        @include mediaw(576px) {
            width: 100%;
        }
        &:nth-child(2n) {
            text-align: left;
            @include mediaw(1199px) {
                text-align: center;
            }
            .advantages__item-description {
                margin-left: initial;
                @include mediaw(1199px) {
                    margin-left: auto;
                }
            }
        }
        &:nth-child(2) {
            .advantages__item-title {
                &::before {
                    top: initial;
                    transform: initial;
                    bottom: -35px;
                    right: -82px;
                    background-color: #08584f;
                }
                &::after {
                    right: -71px;
                    top: initial;
                    transform: initial;
                    bottom: -23px;
                }
                p {
                    &::before {
                        display: none;
                    }
                    &::after {
                        bottom: -11px;
                        right: -60px;
                        top: initial;
                        transform: initial;
                    }
                }
            }
            .advantages__item-title--overlay {
                &::before {
                    top: 37px;
                    right: -50px;
                    transform: rotate(45deg);
                }
            }
            .advantages__item-description {
                max-width: 350px;
            }
        }
        &:nth-child(3) {
            .advantages__item-title {
                &::before {
                    top: initial;
                    transform: initial;
                    bottom: -35px;
                    left: -192px;    
                    background-color: #006a62;
                }
                &::after {
                    left: -180px;
                    top: initial;
                    transform: initial;
                    bottom: -23px;
                }
                p {
                    &::before {
                        width: 115px;
                        left: -135px;
                        top: initial;
                        transform: initial;
                        bottom: 20px;
                    }
                    &::after {
                        bottom: -11px;
                        left: -168px;
                        top: initial;
                        transform: initial;
                    }
                }
            }
            .advantages__item-title--overlay {
                &::before {
                    top: 37px;
                    left: -152px;
                    width: 20px;
                    transform: rotate(-45deg);
                    z-index: 1;
                }
            }
            .advantages__item-description {
                max-width: 250px;
            }
        }
        &:nth-child(4) {
            .advantages__item-title {
                &::before {
                    right: -90px;
                    background-color: #005e4e;
                }
                &::after {
                    right: -78px;
                }
                p {
                    &::before {
                        width: 22px;
                        right: -42px;
                    }
                    &::after {
                        right: -66px;
                    }
                }
            }
            .advantages__item-title--overlay {
                &::before {
                    display: none;
                }
            }
            .advantages__item-description {
                max-width: 260px;
            }
        }
        &:nth-child(5) {
            .advantages__item-title {
                &::before {
                    left: -110px;
                    background-color: #006d60;
                }
                &::after {
                    left: -98px;
                }
                p {
                    &::before {
                        width: 43px;
                        left: -63px;
                    }
                    &::after {
                        left: -87px;
                    }
                }
            }
            .advantages__item-title--overlay {
                &::before {
                    display: none;
                }
            }
            .advantages__item-description {
                max-width: 250px;
            }
        }
        &:nth-child(6) {
            .advantages__item-title {
                &::before {
                    right: -135px;
                    background-color: #006a53;
                }
                &::after {
                    right: -123px;
                }
                p {
                    &::before {
                        width: 44px;
                        right: -88px;
                    }
                    &::after {
                        right: -112px;
                    }
                }
            }
            .advantages__item-title--overlay {
                &::before {
                    top: 37px;
                    right: -53px;
                }
            }
            .advantages__item-description {
                max-width: 305px;
            }
        }
        &:nth-child(7) {
            .advantages__item-title {
                &::before {
                    left: -165px;
                    background-color: #406b75;
                }
                &::after {
                    left: -153px;
                }
                p {
                    &::before {
                        width: 75px;
                        left: -117px;
                    }
                    &::after {
                        left: -142px;
                    }
                }
            }
            .advantages__item-title--overlay {
                &::before {
                    top: 37px;
                    left: -50px;
                    transform: rotate(-45deg);
                }
            }
            .advantages__item-description {
                max-width: 219px;
            }
        }
        &-title--overlay {
            display: inline-block;
            position: relative;
            &::before {
                content: " ";
                display: block;
                width: 50px;
                height: 2px;
                background-color: #fff;
                position: absolute;
                transform: rotate(45deg);
                @include mediaw(1199px) {
                    display: none;
                }
            }
        }
        &-title {
            display: inline-block;
            position: relative;
            &::before {
                content: " ";
                display: block;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                @include mediaw(1199px) {
                    display: none;
                }
            }
            &::after {
                content: " ";
                display: block;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                border: 2px solid #fff;
                @include mediaw(1199px) {
                    display: none;
                }
            }
            p {
                position: relative;
                &::before {
                    content: " ";
                    display: block;
                    background-color: #fff;
                    height: 2px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    @include mediaw(1199px) {
                        display: none;
                    }
                }
                &::after {
                    content: " ";
                    display: block;
                    height: 13px;
                    width: 13px;
                    border-radius: 50%;
                    background-color: #fff;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    @include mediaw(1199px) {
                        display: none;
                    }
                }
            }
            .normal {
                display: inline-block;
                color: #1e1f22;
                background-color: #fff;
                font-size: 24px;
                line-height: 52px;
                padding: 0 16px;
                font-family: $fontUniNeueBold;
                @include mediaw(768px) {
                    font-size: 17px;
                    line-height: 36px;
                    padding: 0 11px;
                }
                &:first-child {
                    position: relative;
                }
            }
            .green {
                color: $colorGreen;
            }
            .block {
                display: block;
                margin-top: 6px;
            }
        }
        &-description {
            font-family: $fontUniNeueRegular;
            font-size: 16px;
            margin-left: auto;
            margin-top: 15px;
            @include mediaw(1199px) {
                margin: 15px auto 0;
                text-align: center;
            }
            @include mediaw(768px) {
                font-size: 14px;
                margin-top: 11px;
            }
        }
        &--centered {
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            margin-top: 45px !important;
            .advantages__item-description {
                margin-left: initial;
                max-width: initial !important;
            }
            .advantages__item-title {
                &::before {
                    left: 50% !important;
                    transform: translateX(-50%) !important;
                    top: -110px;
                    @include mediaw(1199px)
                }
                &::after {
                    left: 50% !important;
                    transform: translateX(-50%) !important;
                    top: -98px;
                    @include mediaw(1199px)
                }
                p {
                    &::before {
                        width: 2px !important;
                        height: 27px !important;
                        top: -50px;
                        left: 50% !important;
                        transform: translateX(-50%) !important;
                        @include mediaw(1199px)
                    }
                    &::after {
                        left: 50% !important;
                        transform: translateX(-50%) !important;
                        top: -87px;
                        @include mediaw(1199px)
                    }
                }
            }
        }
    }
}

.catalog {
    background-color: #fff;
    padding: 120px 0 0 0;
    @include mediaw(768px) {
        padding: 84px 0 0 0;
    }
    @include mediaw(576px) {
        padding: 59px 0 0 0;
    }
    .container {
        position: relative;
        z-index: 1;
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-top: 55px;
        @include mediaw(576px) {
            margin-top: 39px;
        }
    }
    &__item {
        margin-top: 30px;
        width: calc(33.3334% - 20px);
        background-color: #fff;
        box-shadow: 0px 15px 125px 0px rgba(0,0,0,0.1);
        transition: box-shadow .3s;
        @include mediaw(1199px) {
            width: calc(50% - 15px);
        }
        @include mediaw(768px) {
            width: 100%;
        }
        @include mediaw(576px) {
            margin-top: 21px;
        }
        &.brand_of_year {
            position: relative;
            &::after {
                content: " ";
                display: block;
                width: 79px;
                height: 79px;
                background-color: #fff;
                border-radius: 0 0 3px 39px;
                position: absolute;
                top: -4px;
                right: -5px;
                background-image: url(../img/brand_year.png);
                background-size: 60px;
                background-repeat: no-repeat;
                background-position: center center;
                @include mediaw(576px) {
                    width: 55px;
                    height: 55px;
                    background-size: 42px;
                }
            }
        }
        &:hover {
            box-shadow: 0px 15px 125px 0px rgba(0,0,0,0.2);
        }
        &:nth-child(2n) {
            @include mediaw(1199px) {
                margin-left: 30px !important;
            }
            @include mediaw(768px) {
                margin-left: 0 !important;
            }
        }
        &:nth-child(2), &:nth-child(3), &:nth-child(5), &:nth-child(6) {
            margin-left: 30px;
            @include mediaw(1199px) {
                margin-left: initial;
            }
        }
        &-content {
            min-height: 640px;
            padding-bottom: 170px;
            position: relative;
            @include mediaw(768px) {
                min-height: initial;
            }
            @include mediaw(576px) {
                padding-bottom: 130px;
            }
        }
        &-bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 30px;
            @include mediaw(576px) {
                padding: 21px;
            }
        }
        &-slider {
            margin-bottom: 0 !important;
            height: 275px;
            overflow: hidden;
        }
        &-slide {
            height: 100%;
            height: 275px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .slick-arrow {
            background-size: cover;
            width: 15px;
            height: 28px;
            z-index: 1;
            opacity: 0.75;
            transition: opacity .3s;
            @include mediaw(576px) {
                width: 11px;
                height: 20px;
            }
            &:hover {
                opacity: 1;
            }
            &::before {
                display: none;
            }
            &.slick-prev {
                left: 20px;
                background-image: url(../img/slider-prev.png);
                @include mediaw(576px) {
                    left: 14px;
                }
            }
            &.slick-next {
                right: 20px;
                background-image: url(../img/slider-next.png);
                @include mediaw(576px) {
                    right: 14px;
                }
            }
        }
        .slick-dots {
            bottom: 13px;
            li {
                margin: 0 1px;
                button {
                    &::before {
                        color: #fff;
                        opacity: 1;
                    }
                }
                &.slick-active button::before {
                    opacity: 1;
                    color: #48916f;
                }
            }
        }
        &-name {
            margin-top: 20px;
            padding: 0 30px;
            font-size: 24px;
            line-height: 30px;
            color: #1e1f22;
            font-family: $fontUniNeueBold;
            @include mediaw(576px) {
                margin-top: 20px;
                padding: 0 21px;
                font-size: 17px;
                line-height: 34px;
            }
        }
        &-characteristics {
            background-image: url(../img/icon-characteritics.png);
            background-repeat: no-repeat;
            background-position: left 7px;
            margin: 20px 30px 0;
            padding: 0 0 0 30px;
            color: #1e1f22;
            font-size: 18px;
            line-height: 24px;
            font-family: $fontUniNeueBold;
            @include mediaw(576px) {
                background-position: left 5px;
                margin: 0 21px 0;
                padding: 0 0 0 21px;
                font-size: 14px;
                line-height: 17px;
            }
            span {
                color: $colorGreen;
            }
        }
        &-subtitle {
            font-size: 18px;
            font-family: $fontUniNeueBold;
            padding: 0 60px;
            margin: 20px 0 5px;
            @include mediaw(576px) {
                font-size: 14px;
                padding: 0 42px;
                margin: 14px 0 4px;
            }
        }
        &-equipment {
            font-size: 18px;
            font-family: $fontUniNeueBold;
            padding-left: 60px;
            @include mediaw(576px) {
                font-size: 14px;
                padding-left: 42px;
            }
            li {
                position: relative;
                padding-left: 12px;
                @include mediaw(576px) {
                    padding-left: 8px;
                }
                &::before {
                    content: " ";
                    display: block;
                    width: 6px;
                    height: 2px;
                    background-color: $colorGreen;
                    position: absolute;
                    top: 50%;
                    left: 0;
                }
            }
        }
        &-price {
            font-family: $fontUniNeueBold;
            font-size: 24px;
            line-height: 30px;
            color: #1e1f22;
            padding: 0 30px;
            margin-bottom: 20px;
            @include mediaw(576px) {
                font-size: 17px;
                line-height: 21px;
                padding: 0 21px;
                margin-bottom: 14px;
            }
            span {
                color: $colorGreen;
            }
        }
        .btn {
            text-align: center;
            padding-left: 15px;
            padding-right: 15px;
            @include mediaw(576px) {
                padding-left: 11px;
                padding-right: 11px;
            }
        }
        &-advantages {
            li {
                background-image: url(../img/check-symbol.png);
                background-repeat: no-repeat;
                background-position: 30px center;
                padding: 17px 58px;
                font-family: $fontUniNeueBold;
                font-size: 16px;
                color: #fff;
                background-color: #026637;
                @include mediaw(576px) {
                    background-position: 21px center;
                    padding: 12px 41px;
                }
                &:nth-child(2n) {
                    background-color: #03562f;
                }
            }
        }
        &-form {
            padding: 30px;
            @include mediaw(576px) {
                padding: 21px;
            }
            p {
                color: #1e1f22;
                font-size: 18px;
                line-height: 24px;
                font-family: $fontUniNeueBold;
                @include mediaw(576px) {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
            input {
                display: block;
                width: 100%;
            }
            input:not([type="submit"]) {
                height: 50px;
                background-color: #ededed;
                font-family: $fontUniNeueRegular;
                font-size: 17px;
                color: #1e1f22;
                padding: 0 20px;
                margin: 20px 0;
                @include mediaw(576px) {
                    height: 35px;
                    font-size: 14px;
                    padding: 0 14px;
                    margin: 14px 0;
                }
            }
        }
    }
    &__alternative {
        position: relative;
        background-color: #1e1f22;
        padding: 300px 0 150px;
        margin-top: -150px;
        position: relative;
        @include mediaw(768px) {
            padding: 185px 0 75px;
            margin-top: -105px;
        }
        @include mediaw(768px) {
            padding: 130px 0 53px;
            margin-top: -74px;
        }
        &::after {
            content: " ";
            display: block;
            width: 1241px;
            height: calc(100% + 165px);
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../img/background-catalog-alternative.png);
            background-repeat: no-repeat;
            @include mediaw(1350px) {
                left: -100px;
            }
            @include mediaw(1200px) {
                left: -300px;
            }
            @include mediaw(992px) {
                left: -550px;
            }
            @include mediaw(768px) {
                display: none;
            }
        }
        &::before {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-image: url(../img/background-telephone.png);
            background-repeat: no-repeat;
            background-position: center center;
        }
        .container {
            position: relative;
            z-index: 1;
        }
        &-block {
            margin: 0 50px;
            border: 15px solid #fff;
            padding: 75px 0;
            @include mediaw(768px) {
                border-width: 11px;
                padding: 53px 0;
                margin: initial;
            }
        }
        &-content {
            max-width: 340px;
            margin: 0 100px 0 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @include mediaw(768px) {
                max-width: initial;
                margin: initial;
                padding: 0 30px;
            }
            p {
                font-family: $fontUniNeueBold;
                font-size: 24px;
                line-height: 36px;
                color: #fff;
                position: relative;
                @include mediaw(576px) {
                    font-size: 17px;
                    line-height: 25px;
                }
                &::before {
                    content: "...";
                    font-family: $fontUniNeueBold;
                    font-size: 24px;
                    line-height: 36px;
                    color: #fff;
                    position: absolute;
                    left: -22px;
                    top: 0;
                    @include mediaw(576px) {
                        font-size: 17px;
                        line-height: 25px;
                        left: -15px;
                    }
                }
            }
            .btn {
                margin-top: 30px;
                @include mediaw(576px) {
                    margin-top: 21px;
                }
            }
        }
    }
}

.gotest {
    background-image: url(../img/background-gotext.png);
    background-size: cover;
    padding: 150px 0;
    min-height: 720px;
    @include mediaw(992px) {
        padding: 105px 0;
    }
    @include mediaw(576px) {
        padding: 74px 0; 
    }
    &__row {
        display: flex;
        @include mediaw(992px) {
            flex-wrap: wrap;
        }
        .left {
            width: 470px;
            position: relative;
            text-align: center;
            @include mediaw(992px) {
                width: 100%;
            }
            img {
                position: absolute;
                top: -65px;
                left: 40px;
                @include mediaw(992px) {
                    position: static;
                    max-width: 100%;
                }
                @include mediaw(768px) {
                    max-height: 200px;
                }
            }
        }
        .right {
            width: 675px;
            @include mediaw(992px) {
                width: 100%;
                margin-top: 30px;
            }
        }
    }
    &__block {
        max-width: 675px;
        margin-left: auto;
    }
    &__title {
        span {
            display: inline-block;
            color: #1e1f22;
            background-color: #fff;
            padding: 12px 27px;
            font-family: $fontUniNeueBold;
            font-size: 37px;
            @include mediaw(576px) {
                padding: 8px 19px;
                font-size: 26px;
            }
            &:nth-child(2) {
                margin-top: 10px;
                @include mediaw(576px) {
                    margin-top: 7px;
                }
            }
        }
    }
    &__description {
        font-size: 24px;
        line-height: 36px;
        color: #fff;
        font-family: $fontUniNeueBold;
        margin: 35px 0 50px;
        position: relative;
        @include mediaw(576px) {
            font-size: 17px;
            line-height: 25px;
            margin: 25px 0 35px;
        }
        &::before {
            content: " ";
            display: block;
            width: 50px;
            height: 2px;
            background-color: #fff;
            position: absolute;
            top: 18px;
            left: -75px;
            @include mediaw(576px) {
                width: 35px;
                top: 13px;
                left: -53px;
            }
        }
    }
}

.privilege {
    background-color: #fff;
    padding: 135px 0;
    @include mediaw(768px) {
        padding: 95px 0;
    }
    @include mediaw(576px) {
        padding: 67px 0;
    }
    .section-title span {
        &::before, &::after {
            transform: initial;
            top: 65px;
            @include mediaw(576px) {
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &::before {
            left: 30px;
            @include mediaw(576px) {
                left: -48px;
            }
        }
        &::after {
            right: 30px;
            @include mediaw(576px) {
                right: -48px;
            }
        }
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        width: calc(33.3334% - 20px);
        color: #1e1f22;
        position: relative;
        padding-top: 155px;
        margin-top: 45px;
        @include mediaw(992px) {
            width: calc(50% - 15px);
        }
        @include mediaw(768px) {
            width: 100%;
        }
        @include mediaw(576px) {
            padding-top: 109px;
            margin-top: 32px;
        }
        &::before {
            content: " ";
            display: block;
            width: 110px;
            height: 110px;
            background-color: #e5f0eb;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 50px;
            @include mediaw(576px) {
                width: 77px;
                height: 77px;
                left: 35px;
            }
        }
        &::after {
            content: " ";
            font-family: $fontUniNeueBook;
            font-size: 100px;
            color: #1e1f22;
            position: absolute;
            top: 13px;
            left: -6px;
            @include mediaw(576px) {
                font-size: 70px;
                top: 9px;
                left: -4px;
            }
        }
        &:first-child {
            &::after {
                content: "01";
            }
            .privilege__item-title span::after {
                right: calc(100% - 115px);
                @include mediaw(576px) {
                    right: calc(100% - 81px);
                }
            }
        }
        &:nth-child(2) {
            &::after {
                content: "02";
            }
            .privilege__item-title span::after {
                right: calc(100% - 185px);
                @include mediaw(576px) {
                    right: calc(100% - 130px);
                }
            }
        }
        &:nth-child(3) {
            &::after {
                content: "03";
            }
            .privilege__item-title span::after {
                right: calc(100% - 165px);
                @include mediaw(576px) {
                    right: calc(100% - 116px);
                }
            }
        }
        &:nth-child(4) {
            &::after {
                content: "04";
            }
            .privilege__item-title span::after {
                right: calc(100% - 125px);
                @include mediaw(576px) {
                    right: calc(100% - 88px);
                }
            }
        }
        &:nth-child(5) {
            &::after {
                content: "05";
            }
            .privilege__item-title span::after {
                right: calc(100% - 150px);
                @include mediaw(576px) {
                    right: calc(100% - 105px);
                }
            }
        }
        &:nth-child(6) {
            &::after {
                content: "06";
            }
            .privilege__item-title span::after {
                right: calc(100% - 240px);
                @include mediaw(576px) {
                    right: calc(100% - 168px);
                }
            }
        }
        &:first-child, &:nth-child(2), &:nth-child(4), &:nth-child(5) {
            margin-right: 30px;
            @include mediaw(992px) {
                margin-right: 0;
            }
        }
        &:nth-child(2n) {
            @include mediaw(992px) {
                margin-left: 30px;
            }
            @include mediaw(768px) {
                margin-left: 0;
            }
        }
        &-title {
            font-size: 24px;
            line-height: 36px;
            font-family: $fontUniNeueBold;
            @include mediaw(576px) {
                font-size: 17px;
                line-height: 25px;
            }
            span {
                display: block;
                position: relative;
                &::after {
                    content: " ";
                    display: block;
                    width: 30px;
                    height: 2px;
                    background-color: $colorGreen;
                    position: absolute;
                    top: 20px;
                    @include mediaw(576px) {
                        width: 21px;
                        top: 14px;
                    }
                }
            }
        }
        &-description {
            margin-top: 15px;
            font-family: $fontUniNeueRegular;
            font-size: 17px;
            line-height: 30px;
            @include mediaw(576px) {
                margin-top: 11px;
                font-size: 14px;
                line-height: 22px;
            }
        }
    }
}

.gallery {
    background-color: #f9f9f9;
    padding: 135px 0 150px;
    @include mediaw(768px) {
        padding: 95px 0 105px;
    }
    @include mediaw(576px) {
        padding: 67px 0 74px;
    }
    .section-title span {
        max-width: 400px;
        &::before, &::after {
            transform: initial;
            top: 65px;
            @include mediaw(576px) {
                width: 34px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &::before {
            left: 14px;
            @include mediaw(576px) {
                left: -48px;
            }
        }
        &::after {
            right: 14px;
            @include mediaw(576px) {
                right: -48px;
            }
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        margin-top: 85px;
        @include mediaw(768px) {
            flex-wrap: wrap;
        }
    }
    &__item {
        width: calc(33.33334% - 6.66667px);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mediaw(768px) {
            width: 100%;
        }
        .image-block {
            overflow: hidden;
            position: relative;
            font-size: 0;
            margin-top: 10px;
            &:first-child {
                height: 400px;
                width: 100%;
                @include mediaw(576px) {
                    height: 280px;
                }
            }
            &:nth-child(2) {
                width: calc(50% - 5px);
                height: 190px;
                @include mediaw(576px) {
                    height: 133px;
                }
            }
            &:nth-child(3) {
                display: inline-block;
                width: calc(50% - 5px);
                height: 190px;
                @include mediaw(576px) {
                    height: 133px;
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &::after {
                content: " ";
                display: block;
                width: 100%;
                height: 100%;
                background-image: url(../img/icon-zoom.png);
                background-position: center center;
                background-repeat: no-repeat;
                background-color: rgba(1, 103, 55, .25);
                z-index: 2;
                position: absolute;
                top: 0;
                left: 0;
                transition: opacity .3s;
                opacity: 0;
                cursor: pointer;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
                .hover {
                    display: block;
                }
            }
        }
        .hover {
            position: absolute;
            top: -20px;
            left: -20px;
            width: calc(100% + 40px);
            height: calc(100% + 40px);
            overflow: hidden;
            filter: blur(10px);
            z-index: 1;
            display: none;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &:nth-child(2) {
            @include mediaw(768px) {
                flex-direction: row-reverse;
            }
            .image-block {
                &:first-child {
                    width: calc(50% - 5px);
                    height: 190px;
                    @include mediaw(768px) {
                        height: 400px;
                        width: 100%;
                    }
                    @include mediaw(576px) {
                        height: 280px;
                    }
                }
                &:nth-child(2) {
                    width: calc(50% - 5px);
                    height: 190px;
                }
                &:nth-child(3) {
                    height: 400px;
                    width: 100%;
                    @include mediaw(768px) {
                        width: calc(50% - 5px);
                        height: 190px;
                    }
                }
            }
        }
    }
}

.map {
    position: relative;
    background-color: #fff;
    @include mediaw(1800px) {
        display: flex;
    }
    @include mediaw(768px) {
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }
    &__block {
        position: absolute;
        top: 0;
        left: 100px;
        width: 870px;
        height: 100%;
        @include mediaw(1800px) {
            left: 40px;
            position: static;
            width: 50%;
        }
        @include mediaw(768px) {
            width: 100%;
        }
        &::before, &::after {
            content: " ";
            display: block;
            width: 50%;
            height: 100%;
            position: absolute;
            top: 0;
            @include mediaw(1800px) {
                display: none;
            }
        }
        &::before {
            left: 0;
            background-color: #fff;
            @include mediaw(1800px) {
                display: none;
            }
        }
        &::after {
            right: 0;
            background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            @include mediaw(1800px) {
                display: none;
            }
        }
        &-content {
            position: relative;
            z-index: 1;
            max-width: 625px;
            padding: 145px 65px 150px;
            @include mediaw(992px) {
                padding: 102px 65px 105px;
            }
            @include mediaw(768px) {
                padding: 71px 46px 74px 75px;
            }
        }
    }
    iframe {
        width: 100%;
        height: 1040px;
        @include mediaw(1800px) {
            width: 50%;
        }
        @include mediaw(768px) {
            width: 100%;
            height: 400px;
        }
    }
    &__title {
        span {
            display: inline-block;
            font-family: $fontUniNeueBold;
            font-size: 37px;
            color: #fff;
            padding: 15px 25px;
            background-color: #1e1f22;
            @include mediaw(768px) {
                font-size: 26px;
                padding: 11px 18px;
            }
        }
    }
    &__description {
        margin-top: 25px;
        font-family: $fontUniNeueBold;
        color: #1e1f22;
        font-size: 24px;
        position: relative;
        @include mediaw(768px) {
            font-size: 17px;
            margin-top: 18px;
        }
        &::before {
            content: " ";
            display: block;
            width: 69px;
            height: 2px;
            background-color: #1e1f22;
            position: absolute;
            left: -94px;
            top: 15px;
            @include mediaw(768px) {
                width: 48px;
                left: -66px;
                top: 11px;
            }
        }
    }
    &__item {
        &-title {
            font-family: $fontUniNeueBold;
            font-size: 24px;
            color: #222;
            margin-top: 60px;
            position: relative;
            @include mediaw(768px) {
                font-size: 17px;
                margin-top: 42px;
            }
            &::before {
                content: " ";
                display: block;
                width: 65px;
                height: 65px;
                border-radius: 50%;
                background-color: #016737;
                box-shadow: 0px 20px 75px 0px rgba(1,103,55,0.3);
                position: absolute;
                top: -16px;
                left: -97px;
                background-repeat: no-repeat;
                background-position: center center;
                @include mediaw(768px) {
                    width: 46px;
                    height: 46px;
                    top: -11px;
                    left: -68px;
                }
            }
            &.locate::before {
                background-image: url(../img/icon-locate.png);
            }
            &.phone::before {
                background-image: url(../img/icon-phone-white.png);
            }
            span {
                display: block;
                margin-top: 25px;
                font-family: $fontUniNeueRegular;
                font-size: 18px;
                @include mediaw(768px) {
                    margin-top: 18px;
                    font-size: 14px;
                }
            }
        }
        &-phones {
            margin: 30px 0 35px;
            display: flex;
            align-items: center;
            color: #1e1f22;
            cursor: pointer;
            @include mediaw(768px) {
                margin: 21px 0 25px;
            }
            &-prefix {
                font-family: $fontUniNeueRegular;
                font-size: 36px;
                @include mediaw(768px) {
                    font-size: 25px;
                }
            }
            &-list {
                font-family: $fontUniNeueBold;
                font-size: 16px;
                line-height: 17px;
                margin: 0 27px 0 20px;
                @include mediaw(768px) {
                    font-size: 14px;
                    line-height: 16px;
                    margin: 0 19px 0 14px;
                }
            }
        }
    }
    .btn {
        display: inline-block;
    }
}