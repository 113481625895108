*, *::before, *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0); 
    -webkit-tap-highlight-color: transparent;
}

a {
    color: inherit;
    text-decoration: none;
    &:hover {
        color: inherit;
        text-decoration: none;
    }
}

ul {
    list-style: none;
}

h1, h2, p, ul {
    margin-bottom: 0;
}

input, button {
    -webkit-appearance: none;
    border: none;
    border-radius: 0;
    &:focus {
        outline: none;
        box-shadow: none !important;
    }
}

body {
    &.overflow-active {
        overflow: hidden;
    }
}