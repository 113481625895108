@import 'normalize';

@import 'variebles';

@import 'fonts';
@import 'responce';
@import 'header';
@import 'home';
@import 'footer';

body.active, html.active {
    overflow-y: hidden;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    @include mediaw(1200px){
        max-width: 960px;
    }
    @include mediaw(992px){
        max-width: 720px;
    }
    @include mediaw(768px){
        max-width: 540px;
    }
    @include mediaw(576px){
        max-width: 100%;
    }
} 


.btn {
    cursor: pointer;
    font-family: $fontUniNeueBold;
    font-size: 16px;
    color: $colorGreen;
    display: block;
    padding: 15px 36px 15px 46px;
    transition: padding .3s, background-color .3s, color .3s;
    @include mediaw(768px) {
        padding: 11px 25px 11px 32px;
    }
    &:hover {
        padding: 15px 41px;
        background-image: none;
        @include mediaw(768px) {
            padding: 11px 29px;
        }
    }
    &--lines {
        border: 2px solid $colorGreen;
        background-color: #fff;
        position: relative;
        &:hover {
            background-color: $colorGreen;
            color: #fff;
            &::before {
                transform: translateY(-50%) scale(0);
            }
            &::after {
                transform: translateY(-50%) scale(0);
            }
        }
        &::before {
            content: " ";
            display: block;
            width: 2px;
            height: 17px;
            background-color: #fff;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -2px;
            transition: transform .3s;
        }
        &::after {
            content: " ";
            display: block;
            width: 2px;
            height: 17px;
            background-color: #fff;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -2px;
            transition: transform .3s;
        }
    }
    &--phone {
        background-image: url(../img/icon-phone-green.png);
        background-position: 28px center;
        background-repeat: no-repeat;
        @include mediaw(768px) {
            background-position: 15px center;
        }
    }
    &--white {
        background-color: #fff;
        &:hover {
            color: #fff;
            background-color: $colorGreen;
        }
    }
    &--arrow {
        font-family: $fontUniNeueBold;
        font-size: 16px;
        color: #1e1f22;
        background-color: #fff;
        padding: 20px 70px 20px 30px;
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: calc(100% - 30px) center;
        transition: padding .3s, color .3s, background-color .3s;
        @include mediaw(768px) {
            padding: 14px 49px 14px 21px;
            background-position: calc(100% - 21px) center;
        }
        @include mediaw(335px) {
            padding: 14px 49px 14px 21px !important;
            background-position: calc(100% - 21px) center !important;
        }
        &:hover {
            background-image: none;
            padding: 20px 50px;
            color: #fff;
            background-color: #1e1f22;
            @include mediaw(768px) {
                padding: 14px 35px;
            }
        }
        &--green {
            background-image: url(../img/right-arrow-green.svg);
        }
        &--white {
            background-image: url(../img/icon-phone-white.svg);
            background-color: #016737;
            color: #fff;
            &:hover {
                background-image: url(../img/icon-phone-white.svg);
                background-color: lighten(#016737, 10%);
            }
        }
    }
    &--arrow-bg-black {
        background-color: #1e1f22;
        display: inline-block;
        &:hover {
            background-color: $colorGreen;
            background-image: none;
        }
    }
}

.section-title {
    display: flex;
    justify-content: center;
    width: 100%;
    span {
        display: block;
        font-family: $fontUniNeueRegular;
        font-size: 30px;
        position: relative;
        color: #1e1f22;
        max-width: 575px;
        text-align: center;
        @include mediaw(576px) {
            font-size: 21px;
            margin: 0 35px;
        }
        &::before, &::after {
            content: " ";
            display: block;
            width: 48px;
            height: 2px;
            background-color: $colorGreen;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include mediaw(576px) {
                width: 34px;
            }
        }
        &::before {
            left: -68px;
            @include mediaw(576px) {
                left: -48px;
            }
        }
        &::after {
            right: -68px;
            @include mediaw(576px) {
                right: -48px;
            }
        }
        &.no-lines {
            max-width: 100% !important;
            &::before, &::after {
                display: none;
            }
        }
    }
}

.modal--overlay {
    background-image: url(../img/background-popup.png);
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    display: none;
    z-index: 2;
    overflow: auto;
    &::before {
        content: " ";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(16, 20, 37, 0.8);
        z-index: -1;
    }
    &.callModal {
        .section-title span {
            font-family: $fontUniNeueBold;
            max-width: 450px;
            font-size: 24px;
            @include mediaw(992px) {
                font-size: 17px;
            }
            &::before, &::after {
                width: 30px;
            }
            &::before {
                left: 0;
                @include mediaw(1199px) {
                    left: -30px;
                }
            }
            &::after {
                right: 0;
                @include mediaw(1199px) {
                    right: -30px;
                }
            }
        }
        .modal {
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translateX(-50%);
            @include mediaw(992px) {
                width: 440px;
                max-width: calc(100% - 20px);
            }
        }
    }
    .modal--wrapper {
        overflow: auto;
        position: absolute;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        max-height: calc(100vh - 200px);
        overflow: auto;
        max-width: calc(100% - 20px);
    }
    .modal {
        background-color: #fff;
        padding: 60px;
        max-width: 605px;
        @include mediaw(992px) {
            padding: 42px;
        }
        @include mediaw(576px) {
            padding: 29px;
        }
        @include mediaw(480px) {
            padding: 20px;
        }
        &__close {
            background-image: url(../img/icon-close.svg);
            background-size: cover;
            position: absolute;
            right: 30px;
            top: 30px;
            width: 15px;
            height: 15px;
            transition: transform .3s;
            cursor: pointer;
            @include mediaw(576px) {
                right: 15px;
                top: 15px;
            }
            &:hover {
                transform: rotate(180deg);
            }
        }
        label {
            position: relative;
            display: block;
            margin-top: 29px;
            @include mediaw(992px) {
                margin-top: 20px;
            }
            span {
                font-family: $fontUniNeueBold;
                font-size: 18px;
                line-height: 30px;
                color: #231d1f;
                padding: 0 13px;
                background-color: #fff;
                position: absolute;
                top: -15px;
                left: 11px;
                display: block;
                z-index: 1;
                @include mediaw(992px) {
                    font-size: 14px;
                    line-height: 21px;
                    padding: 0 9px;
                    top: -11px;
                    left: 8px;
                }
            }
        }
        input {
            display: block;
            width: 100%;
            &:not([type="submit"]) {
                height: 55px;
                padding: 0 24px;
                font-size: 18px;
                font-family: $fontUniNeueRegular;
                color: #231d1f;
                border: 1px solid #bdbcbd;
                @include mediaw(992px) {
                    height: 39px;
                    padding: 0 17px;
                    font-size: 14px;
                }
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    font-size: 18px;
                    font-family: $fontUniNeueRegular;
                    color: #231d1f;
                    @include mediaw(992px) {
                        font-size: 18px;
                    }
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    font-size: 18px;
                    font-family: $fontUniNeueRegular;
                    color: #231d1f;
                    @include mediaw(992px) {
                        font-size: 18px;
                    }
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    font-size: 18px;
                    font-family: $fontUniNeueRegular;
                    color: #231d1f;
                    @include mediaw(992px) {
                        font-size: 18px;
                    }
                }
                &:-moz-placeholder { /* Firefox 18- */
                    font-size: 18px;
                    font-family: $fontUniNeueRegular;
                    color: #231d1f;
                    @include mediaw(992px) {
                        font-size: 18px;
                    }
                }
            }
            &[type="submit"] {
                margin-top: 35px;
                text-align: center;
                padding-left: 15px;
                padding-right: 15px;
                @include mediaw(992px) {
                    margin-top: 25px;
                    padding-left: 11px;
                    padding-right: 11px;
                }
            }
        }
    }
    &.priceModal {
        .table--wrapper {
            overflow: auto;
        }
        .modal {
            padding-top: 40px;
            padding-bottom: 0;
            width: 1170px;
            max-width: 100%;
            @include mediaw(576px) {
                padding-top: 28px;
            }
        }
        .tg  {
            display: block;
            margin-top: 45px;
            border-collapse: collapse;
            border-spacing: 0;
            @include mediaw(576px) {
                margin-top: 32px;
            }
            td {
                font-family: $fontUniNeueRegular;
                font-size: 18px;
                color: #1e1f22;
                border: 2px solid #e8e8e8;
                padding: 10px;
                vertical-align: middle;
                text-align: center;
                @include mediaw(576px) {
                    font-size: 14px;
                    padding: 7px;
                    min-width: 80px;
                }
            }
            th {
                font-family: $fontUniNeueBold;
                font-size: 18px;
                padding: 25px 30px;
                border: 2px solid #e8e8e8;
                color: #1e1f22;
                vertical-align: middle;
                text-align: center;
                @include mediaw(576px) {
                    font-size: 14px;
                    padding: 5px;
                    min-width: 160px;
                }
            }
        }
        .section-title span {
            font-family: $fontUniNeueBold;
            font-size: 37px;
            @include mediaw(576px) {
                font-size: 26px;
            }
        }
        form {
            position: relative;
            width: calc(100% + 120px);
            left: -60px;
            background-color: #f9f9f9;
            padding: 50px 60px 60px;
            @include mediaw(992px) {
                left: -40px;
                width: calc(100% + 80px);
            }
            @include mediaw(576px) {
                left: -28px;
                width: calc(100% + 56px);
                padding: 35px 42px 42px; 
            }
            @include mediaw(480px) {
                left: -25px;
                width: calc(100% + 45px);
            }
            .section-title span {
                color: #231d1f;
                font-size: 24px;
                font-family: $fontUniNeueBold;
                line-height: 36px;
                @include mediaw(992px) {
                    font-size: 17px;
                    line-height: 25px;
                }
                @include mediaw(576px) {
                    margin: 0 !important;
                }
                &::before, &::after {
                    width: 30px;
                    @include mediaw(576px) {
                        width: 21px;
                    }
                }
                &::before {    
                    left: -26px;
                    @include mediaw(576px) {
                        left: -18px;
                    }
                }
                &::after {    
                    right: -26px;
                    @include mediaw(576px) {
                        right: -18px;
                    }
                }
            }
            .priceModal__form-row {
                display: flex;
                margin-top: 50px;
                @include mediaw(768px) {
                    flex-wrap: wrap;
                }
                @include mediaw(576px) {
                    margin-top: 35px;
                }
            }
            label {
                @include mediaw(768px) {
                    width: 100% !important;
                    margin-right: initial !important;
                    margin-top: 15px !important;
                }
                span {
                    background-color: #f9f9f9;
                }
                input {
                    background-color: #f9f9f9;
                }
                &:first-child, &:nth-child(2) {
                    width: calc((100% - 240px) / 2);
                    margin-right: 30px;
                    margin-top: 0;
                }
            }
            .btn {
                width: 210px;
                margin-top: 0;
                padding-top: 16.5px;
                padding-bottom: 16.5px;
                text-align: left;
                padding-left: 50px;
                @include mediaw(992px) {
                    height: 39px;
                    padding-top: 7.5px;
                    padding-bottom: 7.5px;
                }
                @include mediaw(768px) {
                    margin: 15px auto 0;
                }
                @include mediaw(335px) {
                    padding-top: 8px !important;
                    padding-bottom: 8px !important;
                }
            }
        }
    }
}

.quiz--overlay {
    display: none;
    align-items: center;
    justify-content: center;
    .quiz {
        .section-title span {
            font-family: $fontUniNeueBold;
            font-size: 37px;
            color: #fff;
            max-width: 730px;
            @include mediaw(768px) {
                font-size: 26px;
            }
            @include mediaw(576px) {
                font-size: 18px;
            }
            &::before, &::after {
                background-color: #fff;
            }
        }
        &__row {
            display: flex;
            margin-top: 65px;
        }
        &__col {
            width: 50%;
            padding: 0 60px;
            @include mediaw(992px) {
                padding: 0 15px;
            }
            cursor: pointer;
            &:first-child {
                position: relative;
                &::after {
                    content: " ";
                    display: block;
                    height: 67px;
                    width: 1px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -0.5px;
                    background-color: #608777;
                }
            }
            &:hover {
                .image-block::before {
                    opacity: 1;
                }
            }
            &.active {
                .image-block {
                    &::before {
                        top: -10px;
                        left: -10px;
                        width: calc(100% + 20px);
                        height: calc(100% + 20px);
                        opacity: 1;
                    }
                }
                .quiz__text span::after {
                    opacity: 1;
                }
            }
            .image-block {
                height: 198px;
                width: 198px;
                border-radius: 50%;
                margin: 0 auto;
                transition: box-shadow .3s;
                position: relative;
                z-index: 1;
                @include mediaw(490px) {
                    height: 150px;
                    width: 150px;
                }
                @include mediaw(400px) {
                    height: 107px;
                    width: 107px;
                }
                &::before {
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background-color: #fff;
                    box-shadow: 0px 0px 100px 0px rgba(29,33,34,1);
                    z-index: -1;
                    opacity: 0;
                    transition: opacity .3s, top .1s, left .1s, width .1s, height .1s;
                    z-index: -1;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .quiz__text {
                font-family: $fontUniNeueRegular;
                font-size: 24px;
                color: #fff;
                margin-top: 40px;
                @include mediaw(576px) {
                    font-size: 17px;
                }
                span {
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    border: 1px solid rgba(255,255,255,0.4);
                    margin-right: 20px;
                    position: relative;
                    @include mediaw(576px) {
                        height: 14px;
                        width: 14px;
                        margin-right: 14px;
                    }
                    &::after {
                        content: " ";
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #fff;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        opacity: 0;
                        transition: opacity .3s;
                        @include mediaw(576px) {
                            width: 7px;
                            height: 7px;
                        }
                    }
                }
            }
        }
        &__step {
            position: relative;
            display: none;
            min-height: 420px;
            &-description {
                font-family: $fontUniNeueBold;
                font-size: 24px;
                color: #fff;
                text-align: center;
                margin: 30px 0 10px;
                @include mediaw(576px) {
                    font-size: 17px;
                    margin: 21px 0 7px;
                }
            }
            &.active {
                display: block;
            }
            .btn {
                position: absolute;
                right: -250px;
                bottom: -10px;
                @include mediaw(1350px) {
                    right: -50px;
                    bottom: -60px;
                }
                @include mediaw(992px) {
                    right: 0 !important;
                }
            }
            &-current {
                display: flex;
                align-items: center;
                position: absolute;
                left: -240px;
                bottom: -10px;
                @include mediaw(1350px) {
                    left: -50px;
                    bottom: -60px;
                }
                @include mediaw(992px) {
                    left: 0;
                }
                span {
                    display: block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #fff;
                    margin: 0 9px;
                    &.current {
                        width: 50px;
                        height: 50px;
                        background-size: cover;
                        background-color: transparent;
                        position: relative;
                        &::after {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            font-family: $fontUniNeueBold;
                            font-size: 17px;
                            color: #fff;
                        }
                    }
                }
            }
            &--first {
                .quiz__step-current span.current {
                    background-image: url(../img/quiz-progress1.png);
                    &::after {
                        content: "01";
                    }
                }
            }
            &--second {
                .quiz__step-current span.current {
                    background-image: url(../img/quiz-progress2.png);
                    &::after {
                        content: "02";
                    }
                }
            }
            &--third {
                .quiz__step-current span.current {
                    background-image: url(../img/quiz-progress3.png);
                    &::after {
                        content: "03";
                    }
                }
            }
            &--last {
                form {
                    position: relative;
                    padding: 50px 60px 60px;
                    @include mediaw(768px) {
                        padding: 35px 42px 42px;
                    }
                    @include mediaw(576px) {
                        padding: 25px 29px 29px;
                    }
                    .quiz__form-row {
                        display: flex;
                        margin-top: 50px;
                        @include mediaw(1200px) {
                            flex-wrap: wrap;
                        }
                        @include mediaw(576px) {
                            margin-top: 35px;
                        }
                    }
                    label {
                        position: relative;
                        display: block;
                        margin-top: 29px;
                        @include mediaw(576px) {
                            margin-top: 20px;
                            width: 100% !important;
                            margin: 15px 0 0 !important;
                        }
                        &:first-child, &:nth-child(2) {
                            width: calc((100% - 240px) / 2);
                            margin-right: 30px;
                            margin-top: 0;
                            @include mediaw(1200px) {
                                width: calc(50% - 15px);
                                margin-right: 0;
                            }
                            input {
                                min-width: 385px;
                            }
                        }
                        &:nth-child(2) {
                            @include mediaw(1200px) {
                                margin-left: 30px;
                            }
                        }
                        span {
                            font-family: $fontUniNeueBold;
                            font-size: 18px;
                            line-height: 30px;
                            color: #fff;
                            padding: 0 13px;
                            background-color: #317362;
                            position: absolute;
                            top: -15px;
                            left: 11px;
                            display: block;
                            z-index: 1;
                            @include mediaw(576px) {
                                font-size: 14px;
                                line-height: 21px;
                                padding: 0 9px;
                                top: -11px;
                                left: 8px;
                            }
                        }
                    }
                    input {
                        display: block;
                        width: 100%;
                        &:not([type="submit"]) {
                            @include mediaw(992px) {
                                min-width: initial !important;
                            }
                            height: 55px;
                            padding: 0 24px;
                            font-size: 18px;
                            font-family: $fontUniNeueRegular;
                            color: #fff;
                            border: 1px solid #bdbcbd;
                            background-color: transparent;
                            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                font-size: 18px;
                                font-family: $fontUniNeueRegular;
                                color: #231d1f;
                            }
                            &::-moz-placeholder { /* Firefox 19+ */
                                font-size: 18px;
                                font-family: $fontUniNeueRegular;
                                color: #231d1f;
                            }
                            &:-ms-input-placeholder { /* IE 10+ */
                                font-size: 18px;
                                font-family: $fontUniNeueRegular;
                                color: #231d1f;
                            }
                            &:-moz-placeholder { /* Firefox 18- */
                                font-size: 18px;
                                font-family: $fontUniNeueRegular;
                                color: #231d1f;
                            }
                        }
                        &[type="submit"] {
                            text-align: center;
                            padding-left: 15px;
                            padding-right: 15px;
                            @include mediaw(1200px) {
                                position: relative;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                    .btn {
                        position: static;
                        width: 210px;
                        margin-top: 0;
                        padding-top: 16.5px;
                        padding-bottom: 16.5px;
                        text-align: left;
                        padding-left: 50px;
                    }
                }
            }
        }
    }
}