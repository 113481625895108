.header {
    min-height: 100vh;
    position: relative;
    @include mediaw(992px) {
        min-height: initial;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        position: relative;
        @include mediaw(992px) {
            flex-wrap: wrap;
            padding-bottom: 10px;
        }
        .left {
            display: flex;
            align-items: center;
            @include mediaw(992px) {
                width: 100%;
            }
        }
        .center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            @include mediaw(992px) {
                right: 0;
                left: initial;
                top: 10px;
                transform: initial;
            }
            @include mediaw(420px) {
                width: 42px;
                height: 42px;
                right: 5px;
            }
        }
        .right {
            display: flex;
            align-items: center;
            @include mediaw(992px) {
                width: 100%;
                justify-content: flex-end;
            }
            @include mediaw(500px) {
                max-width: calc(100% - 110px);
                margin-left: auto;
                justify-content: center;
                flex-wrap: wrap;
            }
        }
    }
    &__logo {
        background-color: $colorGreen;
        display: block;
        padding: 15px 20px;
        @include mediaw(992px) {
            position: relative;
            &::after {
                content: " ";
                position: absolute;
                display: block;
                width: 100%;
                height: 66px;
                background-color: $colorGreen;
                left: 0;
                bottom: 0;
                transform: translateY(100%);
                @include mediaw(530px) {
                    height: 95px;
                }
                @include mediaw(500px) {
                    height: 180px;
                }
            }
        }
        @include mediaw(480px) {
            padding: 15px 14px;
        }
    }
    &__description {
        font-family: $fontUniNeueRegular;
        font-size: 16px;
        line-height: 22px;
        color: #1e1f22;
        margin-left: 20px;
        max-width: 240px;
        @include mediaw(576px) {
            font-size: 14px;
            line-height: 19px;
            margin: 0 61px 0 14px;
        }
        @include mediaw(420px) {
            margin: 0 42px 0 14px;
        }
    }
    &__phones {
        display: flex;
        align-items: center;
        color: #1e1f22;
        cursor: pointer;
        @include mediaw(530px) {
            flex-wrap: wrap;
        }
        @include mediaw(500px) {
            width: 100%;
        }
        &-prefix {
            font-family: $fontUniNeueRegular;
            font-size: 36px;
            @include mediaw(576px) {
                font-size: 25px;
            }
            @include mediaw(530px) {
                width: 100%;
                text-align: center;
            }
        }
        &-list {
            font-family: $fontUniNeueBold;
            font-size: 16px;
            line-height: 17px;
            margin: 0 27px 0 20px;
            @include mediaw(768px) {
                margin: 0 19px 0 14px;
            }
            @include mediaw(530px) {
                width: 100%;
                text-align: center;
                margin: 0;
            }
        }
    }
    &__bottom {
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-family: $fontUniNeueBold;
        font-size: 18px;
        padding: 65px 0;
        @include mediaw(992px) {
            position: initial;
        }
        @include mediaw(768px) {
            font-size: 14px;
            padding: 46px 0;
        }
        &-block {
            max-width: 790px;
            margin-left: auto;
            display: flex;
            align-items: center;
            @include mediaw(1199px) {
                margin-left: initial;
                justify-content: center;
                max-width: initial;
            }
            @include mediaw(576px) {
                flex-wrap: wrap;
            }
        }
    }
    &__delivery {
        background-image: url(../img/icon-delivery-truck.png);
        background-position: left center;
        background-repeat: no-repeat;
        padding: 0 0 0 80px;
        max-width: 280px;
        @include mediaw(768px) {
            padding: 0 0 0 70px;
        }
        @include mediaw(576px) {
            max-width: initial;
            width: 100%;
            padding: 18px 0 18px 70px;
        }
    }
    &__payment {
        background-image: url(../img/icon-payment.png);
        background-position: left center;
        background-repeat: no-repeat;
        padding: 0 0 0 70px;
        max-width: 155px;
        margin-left: 25px;
        @include mediaw(992px) {
            margin-left: 10px;
        }
        @include mediaw(768px) {
            padding: 7px 0 7px 51px;
        }
        @include mediaw(576px) {
            max-width: initial;
            width: 100%;
            margin: 10px 0 0;
            padding: 18px 0 18px 70px;
            background-position-x: 8px;
        }
    }
    &__parts {
        background-image: url(../img/icon-parts.png);
        background-position: left center;
        background-repeat: no-repeat;
        padding: 3px 0 3px 75px;
        max-width: 300px;
        margin-left: 25px;
        @include mediaw(992px) {
            margin-left: 10px;
        }
        @include mediaw(768px) {
            padding-left: 55px;
        }
        @include mediaw(576px) {
            max-width: initial;
            width: 100%;
            margin: 10px 0 0;
            padding: 18px 0 18px 70px;
            background-position-x: 5px;
        }
    }
    .container {
        position: relative;
        .header__gradient {
            width: 318px;
            height: calc(100vh - 81px);
            background: linear-gradient(to bottom, rgba(1,103,55,1) 0%, rgba(8,96,58,1) 100%);
            position: absolute;
            top: 81px;
            z-index: 1;
            @include mediaw(1199px) {
                display: none;
            }
            &::after {
                content: " ";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-image: url(../img/header-dots.png);
                background-repeat: no-repeat;
                background-position: 45px 70px;
            }
            &::before {
                content: " ";
                display: block;
                width: 742px;
                height: 296px;
                position: absolute;
                bottom: 0;
                left: -355px;
                transform: translateY(100%);
                background-image: url(../img/screen2-pyatno.png);
                background-repeat: no-repeat;
            }
            a {
                display: block;
                position: absolute;
                bottom: 106px;
                left: 64px;
                transform: rotate(-90deg);
                font-family: $fontUniNeueBold;
                font-size: 16px;
                color: #fff;
                background-image: url(../img/left-arrow-white.svg);
                background-position: left center;
                background-repeat: no-repeat;
                background-size: 20px;
                padding: 24px 22px 24px 38px;
                transition: padding .3s, bottom .3s;
                z-index: 1;
                &:hover {
                    padding: 24px 10px 24px 50px;
                    bottom: 100px;
                }
            }
        }
    }
    &__content {
        display: flex;
        align-items: center;
        position: relative;
        height: calc(100vh - 265px);
        background-image: url(../img/background-header.png);
        background-size: cover;
        @include mediaw(1199px) {
            &::before {
                content: " ";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0,0,0,0.25);
            }
        }
        @include mediaw(992px) {
            height: initial;
            padding: 50px 0;
        }
        @include mediaw(576px) {
            padding: 35px 0;
        }
        .container {
            width: 100%;
            padding-left: 140px;
            position: relative;
            z-index: 2;
            @include mediaw(992px) {
                padding-left: 15px;
            }
        }
    }
    &__title {
        span {
            display: inline-block;
            background-color: #1e1f22;
            color: #fff;
            font-size: 37px;
            font-family: $fontUniNeueBold;
            padding: 15px 25px;
            @include mediaw(576px) {
                padding: 11px 18px;
                font-size: 26px;
            }
            &:nth-child(2) {
                margin-top: 10px;
                @include mediaw(576px) {
                    margin-top: 7px;
                }
            }
        }
    }
    &__title-description {
        font-family: $fontUniNeueBold;
        font-size: 24px;
        color: #fff;
        position: relative;
        padding-left: 75px;
        margin-top: 25px;
        @include mediaw(576px) {
            font-size: 17px;
            margin-top: 18px;
            padding-left: 53px;
        }
        &::before {
            content: " ";
            display: block;
            width: 50px;
            height: 2px;
            background-color: #fff;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            @include mediaw(576px) {
                width: 35px;
            }
        }
    }
    .btnCallModal {
        @include mediaw(500px) {
            margin-top: 15px;
        }
        @include mediaw(335px) {
            padding: 11px 16px 11px 23px;
            background-position: 9px center;
        }
        &:hover {
            @include mediaw(335px) {
                padding: 11px 20px;
            }
        }
    }
    .btn--arrow {
        margin-top: 40px;
        display: inline-block;
        @include mediaw(576px) {
            margin-top: 28px;
        }
        @include mediaw(500px) 
    }
}