.footer {
    background-color: #1e1f22;
    position: relative;
    font-family: $fontUniNeueRegular;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    @include mediaw(1199px) {
        padding-bottom: 15px;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @include mediaw(1199px) {
            flex-wrap: wrap;
        }
        .left {
            display: flex;
            align-items: center;
            @include mediaw(1199px) {
                width: 100%;
                justify-content: center;
            }
        }
        .right {
            display: flex;
            align-items: center;
            text-align: right;
            span {
                display: block;
            }
            @include mediaw(1199px) {
                margin-top: 15px;
                width: 100%;
                text-align: center;
                justify-content: center;
            }
        }
    }
    &__logo {
        background-color: $colorGreen;
        display: block;
        padding: 15px 20px;
    }
    &__description {
        margin-left: 20px;
        max-width: 240px;
    }
    &__privacy {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        opacity: 0.25;
        transition: opacity .3s;
        @include mediaw(1199px) {
            position: initial;
            transform: initial;
            margin-top: 15px;
            width: 100%;
            text-align: center;
            display: inline-block;
        }
        &:hover {
            opacity: 1;
        }
        a {
            display: inline-block;
            position: relative;
            &::after {
                content: " ";
                width: 100%;
                height: 1px;
                background-color: #fff;
                position: absolute;
                bottom: -7px;
                left: 0;
            }
        }
    }
}